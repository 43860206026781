@spinner_black: url("../images/spinner_black.png");
@spinner_white: url("../images/spinner_white.png");

@repository_icons_black: url('../images/repository_icons_black.png');
@repository_icons_multiselect: url('../images/repository_icons_multiselect.png');
@repository_add: url('../images/repository_add.png');
@repository_share: url('../images/repository_share.png');

@invite_warning: url('../images/warning_outline.png');

@sharepoint_position: 0px;
@local_position: -18px;
@bpi_position: -36px;
@folder_position: -54px;
@file_position: -72px;
@cmis_position: -90px;
@public_position: -108px;
@caret_right: -126px;
@personal_position: -144px;
@group_position: -162px;
@newFolder_position: -180px;

@hover_color: #dddddd;
@selection_color: #094ab2;
@normal_color: #fff;

/*
 * Utility functions
 */
.removeSelection() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ellipsis() {
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;
}

.box-sizing() {
    &,
    *,
    *:before,
    *:after {
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
}

.button() {
    border: 1px solid #ababab;
    padding: 2px 10px;
    background-color: #ffffff;
    color: grey;
    cursor:default;
    outline: none;

    &.enabled {
        cursor: pointer;
        color: #333;
        &:hover:not(.loading) {
            background-color: @hover_color;
            border-color: @normal_color;
        }
    }

    & + button {
        margin-left: 5px;
    }

    &.loading {
        padding-left: 10px;

        &:before {
            content: '';
            height: 18px;
            width: 18px;
            position: relative;
            top: 1px;
            display: inline-block;
            vertical-align: top;
            margin: 0 2px;
            background: @spinner_black;
            .icon-spin();
        }
    }
}

.inlineButton() {
    position: relative;
    border: none;
    height: 100%;
    background-color: @normal_color;
    cursor:default;
    display: none;
    margin-right: 5px;

    &[command] {
        vertical-align: middle;
        .icon {
            background: url("../images/repository_buttons.png");
            background-repeat: no-repeat;
            display: inline-block;
            height: 24px;
            margin-left: 3px;
            margin-right: 3px;
            width: 24px;
            vertical-align: middle;
        }
        &:focus {
            outline: none;
        }
    }

    &[command="create"] .icon {
        background-position: 0 0;
    }

    &[command="upload"] .icon {
        background-position: -96px 0;
    }

    &[command="download"] .icon {
        background-position: -48px 0;
    }

    &[command="versions"] .icon {
        background-position: -120px 0;
    }

    &[command="delete"] .icon {
        background-position: -24px 0;
    }

    &[command="rename"] .icon {
        background-position: -72px 0;
    }

    &[command="moveTo"] .icon {
        background-position: -144px 0;
    }

    &[command="browseFolder"] .icon {
        background-position: -192px 0;
    }

    &[command="copyTo"] .icon, &[command="restore"] .icon, &[command="promote"] .icon {
        background: none;
        i {
            margin-top: 5px;
        }
    }

    &.enabled {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        color: #fff;
        &:hover:not(.loading) {
            background-color: @hover_color;
        }
    }

    &.loading {
        padding-left: 5px;

        &:before {
            content: '';
            height: 18px;
            width: 18px;
            position: relative;
            top: 3px;
            display: inline-block;
            vertical-align: top;
            margin: 0 2px;
            background: @spinner_white;
            .icon-spin();
        }
    }
}

.primaryButton() {
    .button;
    &.enabled {
        background-color: @normal_color;
        color: #fff;
    }
    border-color: @normal_color;

    &.loading:before {
        background: @spinner_white;
    }
}

.sideBarTopButton() {
    position: absolute;
    outline: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    right: 15px;
    top: 15px;
    // padding: 15px;
    background: transparent;
    -webkit-appearance: none;
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
}

/*
 * Message Plugin
 */
.user-message-background {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background-color: #cccccc;
    opacity: 0.5;
    z-index: 50;
}

.user-message-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: auto;
    z-index: 100;

    .user-message {
        position: relative;
        margin: 30px auto;
    }
}

.user-message {
    background-color: #FFFFFF;
    position: relative;
    margin: 30px auto;
    width: 315px;
    border: 1px solid #7d7d7d;
    line-height: 20px;
    font-size: 14px;
    z-index:100;

    .user-message-title {
        border-bottom: 1px solid #e1e1e1;
        padding: 15px;
        font-weight: bold;
        color: @normal_color;
    }

    .user-message-message {
        padding: 15px;
        margin-bottom: 10px;
    }

    button {
        .button;
    }

    .user-message-buttons {
        text-align: right;
        padding: 15px;
        border-top: 1px solid #e1e1e1;

        button:first-child {
            .primaryButton;
        }
    }
}


/*
 * Share modal
 */
.share, .user-message.invite {
    .box-sizing;
    width: 700px;

    label {
        font-weight: bold !important;
    }

    #global-access {
        list-style: none;
        padding: 0;

        li.user-access {
            padding-top: 10px;
        }
    }

    .global-access  {
        padding: 15px;

        &.admin {
            .global-access-right {
                cursor: pointer;
            }

            i {
                background: @repository_share;
                background-repeat: no-repeat;
                display: inline-block;
                height: 14px;
                width: 19px;
                vertical-align: middle;

                &.caret-down {
                    width: 8px;
                    background-position: -11px 0;
                    margin-left: 2px;
                    color: #333;
                }
            }
        }

        .global-access-right {
            margin-right: 20px;
        }

        .caret-down {
            display: none;
        }

        span {
            display: inline-block;

            &.left {
                width:80%;
            }

            &.right {
                width: 20%;
                text-align: right;
            }
        }
    }

    .bottom-border {
        &.row {
            padding: 15px;
        }
        border-bottom: 1px solid #ebebeb;
    }

    .user-message-title {
        &.loading {
            &:after {
                content: '';
                height: 18px;
                width: 18px;
                position: relative;
                left: 10px;
                top: 0px;
                display: inline-block;
                vertical-align: top;
                background: @spinner_black;
                .icon-spin;
            }
        }

        button.close {
            float:right;
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            color: #000;
            opacity: 0.2;
            text-shadow: 0 1px 0 #fff;
            border:none;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .user-message-message {
        padding: 15px;
        min-height: 200px;

        &.read-only {
            i, .invite {
                display:none !important;
            }

            .r-message {
                margin-top: 15px;
            }

            .users li span.user-rights{
                cursor: not-allowed;
            }
        }

        ul.users, ul.groups {
            list-style: outside none none;
            padding: 0;
            width: 100%;
            overflow-y: auto;
            overflow-x: auto;
            margin: 0;

            li {
                //.itemLi;
                position: relative;

                border-bottom: 1px solid #ebebeb;
                padding: 10px 0;

                span {
                    display: inline-block;

                    &.left {
                        width:80%;
                    }

                    &.right {
                        width: 20%;
                        text-align:right;

                        i {
                            background: @repository_share;
                            background-repeat: no-repeat;
                            display: inline-block;
                            height: 14px;
                            width: 19px;
                            vertical-align: middle;

                            &.caret-down {
                                width: 8px;
                                background-position: -11px 0;

                            }

                            &.remove {
                                width: 11px;
                            }
                        }

                        .user-rights {
                            padding: 0 5px;
                            cursor: pointer;
                            margin-right: 7px;
                        }

                        .remove {
                            cursor: pointer;
                        }
                    }

                    &.r-message {
                        display: none;
                        margin-left: 5px;
                    }
                }

                &.loading {
                    .user-email:after {
                        content: '';
                        height: 18px;
                        width: 18px;
                        position: relative;
                        left: 10px;
                        top: 3px;
                        display: inline-block;
                        vertical-align: top;
                        background: @spinner_black;
                        .icon-spin;
                    }
                }

                &.invite {
                    position:relative;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border-bottom: none;

                    .user-email {
                        width: 50%;
                        input {
                            width: 99%;
                            padding: 1px 5px;
                            border-width: 1px;
                            border-style: solid;
                            border-color: @normal_color;
                            line-height: 24px;
                            height: 28px;
                            font-size: 1em;

                            &:focus {
                                border: 1px solid @normal_color;
                                outline: none;
                            }
                        }
                    }

                    button.addUser, button.addMember {
                        .primaryButton();
                        line-height: 22px;
                        display: inline-block;
                    }
                }
                button.invite {
                    .primaryButton();
                }

                button.share-groups {
                    .primaryButton();
                }
            }
        }

        &.members, &.groups {
            button.invite {
                .primaryButton();
                margin-top: 10px;
            }

            button.share-groups {
                .primaryButton();
                margin-top: 10px;
            }
        }
    }
}

/*
 * Share modal
 */
.versionModal {
    .box-sizing;
    width: 700px;

    .user-message-title {
        &.loading {
            &:after {
                content: '';
                height: 18px;
                width: 18px;
                position: relative;
                left: 10px;
                top: 0px;
                display: inline-block;
                vertical-align: top;
                background: @spinner_black;
                .icon-spin;
            }
        }

        button.close {
            float:right;
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            color: #000;
            opacity: 0.2;
            text-shadow: 0 1px 0 #fff;
            border:none;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .user-message-message {
        padding: 15px;

        ul.versions {
            list-style: outside none none;
            padding: 0;
            width: 100%;
            margin: 0;

            li {
                position: relative;
                padding: 0 5px;
                .removeSelection();
                font-size: 1.1em;
                height: 28px;
                line-height: 28px;

                span {
                    position: absolute;
                    display: inline-block;
                    .ellipsis();
                }

                &.title {
                    border-bottom: 1px solid #ebebeb;
                }

                &.item.current {
                    border: 1px solid @selection_color;
                }

                &.item:hover {
                    background-color: @hover_color;
                    cursor: pointer;
                }

                &.item.active {
                    background-color: @selection_color;
                }

                &.noVersion {
                    margin-top: 10px;
                    border: none;
                }
            }
        }
    }
}

/*
 * Invite Modal
 */
.user-message.invite {
    .user-message-message{
        .step1 {
            ul.users {
                overflow: visible;

                .item {
                    padding-left: 4px;
                    padding-right: 4px;
                    .left, .right {
                        line-height: 18px;
                        vertical-align: middle;
                        span {
                            vertical-align: middle;
                        }
                    }

                    span.icon {
                        display: inline-block;
                        background-size: 18px 18px;
                        background-origin: 2px 0;
                        background-image: @invite_warning;
                        background-repeat: no-repeat;
                        margin-right: 4px;
                        width: 18px;
                        height: 20px;
                    }

                    span.user-name {
                        margin-right: 3px;
                    }

                    &.warning {
                        background-color: #fcf8e3;
                    }
                    &.error {
                        background-color: #f2dede;
                    }

                    &.invite > * {
                        vertical-align: middle;
                    }
                }
            }
        }

        .step2 {
            .email_subject {
                width: 100%;
                padding: 5px;
            }
            .email_message {
                margin: 5px 0;
                width: 100%;
                height: 200px;
                padding: 5px;
            }
            .email_confirmation {
                margin-top: 5px;
                margin-right: 3px;
            }
        }
    }
}

/*
 * Repository
 */
div.repository-navigator {
    font-size: 14px;
    .box-sizing;

    div.repository-navigator-commandbar {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 40px;
        padding-left: 30%;
        background: @normal_color;

        .removeSelection;

        button {
            .inlineButton;
        }
        .dropdown-content {
            left: 0px;
            top: 100%;
        }
        button.hover .dropdown-content, button:focus .dropdown-content {
            display: block;
        }
    }

    .search {
        margin-bottom: 0px;
        padding: 5px;
        float: right;
        position: relative;

        .fa-search {
            position: absolute;
            top: 14px;
            left: 10px;
        }

        .search-input {
            border-radius: 0;
            width: 100%;
            padding: 0 20px;
            height: 32px;
            box-shadow: none;
            &:focus {
                border-color:@hover_color;
            }
        }

        .search-clear {
            position: absolute;
            right: 10px;
            top: 15px;
            color: #b3b3b3;
            cursor: pointer;
            &:hover {
                color: #8f8f8f;
                text-decoration: none;
            }
        }
    }


    .navigator-status() {
        height: 42px;
        padding-left: 20px;
        line-height: 42px;
        .removeSelection;
        .ellipsis;

        div.folderName {
            display: inline;
            padding-right: 20px;
            font-size: 150%;
        }

        div.locationBar {
            display: inline;
            .ellipsis;
            span {
                &.path-separator {
                    background: @repository_icons_black;
                    background-repeat: no-repeat;
                    background-position: @caret_right * 10 / 18;
                    background-size: 120px 10px; // 10 icons * 18px each * 10/18 ratio => 100px;
                    display: inline-block;
                    height: 10px;
                    margin-left: 3px;
                    margin-right: 3px;
                    width: 10px;
                }

                &.path {
                    color: #000000;
                    cursor: pointer;

                    &:hover {
                        color: rgba(0, 0, 0, 0.8);
                    }

                    &.loading:before {
                        content: '';
                        height: 18px;
                        width: 18px;
                        position: relative;
                        top: 14px;
                        display: inline-block;
                        vertical-align: top;
                        margin: 0 3px;
                        background: @spinner_black;
                        .icon-spin();
                    }
                }

                &:not(.path):not(.path-separator) {
                    color: #666;
                }
            }
            &.loading {
                &:after {
                    content: '';
                    height: 18px;
                    width: 18px;
                    position: relative;
                    left: 10px;
                    top: 15px;
                    display: inline-block;
                    vertical-align: top;
                    background: @spinner_black;
                    .icon-spin;
                }
            }     
        }
    }

    .repository-navigator-center {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 40px;
        bottom: 0px;
        overflow: hidden;

        .navigator-repositories {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 70%;
            bottom: 0px;
            border-right: solid 1px #c0c0c0;
            .removeSelection;
            .repositoryContainer;

            &:focus {
                outline: none;
            }

            .title {
                cursor: default;
                height: 42px;
                padding-left: 20px;
                line-height: 42px;
                .removeSelection;
                .ellipsis;
                padding-right: 20px;
                font-size: 150%;
                border-bottom: solid 1px #c0c0c0;
            }

            .repositories-browser {
                position: absolute;
                width: 100%;
                top: 0px;
                bottom: 0px;
                overflow-x: auto;
                overflow-y: auto;

                span.edit {
                    margin-left: 23px;
                    display: inline-block;
                    width: 85%;
                    input {
                        width: 99%;
                        padding: 1px 5px;
                        border-width: 1px;
                        border-style: solid;
                        border-color: @normal_color;
                        line-height: 24px;
                        height: 28px;
                        font-size: 1em;

                        &:focus {
                            border: 1px solid @normal_color;
                            outline: none;
                        }
                    }
                }

                .item {
                    .ellipsis;
                }
            }
        }

        .repository-navigator-tree {
            position:absolute;
            top: 0px;
            left: 30%;
            right: 0px;
            bottom: 0px;
            overflow-y: auto;
            background-color: #fff;
            .removeSelection;

            &:focus {
                outline: none;
            }

            .repository-navigator-status {
                border-bottom: solid 1px #c0c0c0;
                .navigator-status;
            }

            .navigator-column {
                position:absolute;
                top: 42px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                overflow-y: auto;
                .repositoryNavigator;
            }

            .search-more {
                text-align: center;
                color: #333;
                margin-top: 15px;
                color: @normal_color;
                &:hover {
                    color: @hover_color;
                    cursor: pointer;
                }
    
            }
        }

        .selectMessage {
            font-size: 1.5em;
            left: 30%;
            top: 45%;
            right: 0;
            position: absolute;
            z-index: -1;
            text-align: center;
        }
    }

    .dragInProgress {
        border: 5px dashed #A0A0A0;
    }

    .dragInProgress * { // IE11 +
        pointer-events:none;
    }

    &.readOnly {
        .repository-navigator-center {
            top: 0;
        }
    }

    .search-clear {
        display: none;
    }

    &.search {
        [command="create"], [command="copyTo"], [command="moveTo"], [command="restore"] {
            display: none !important;
        }
        .search-clear {
            display: inline;
        }
    }

    &.globalSearch {
        .navigator-column {
            .header i.fa {
                display: none;
            }
            .global-search-repo-name {
                color: @normal_color;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }

}

.repository-navigator-contextual-menu {
    position: fixed;
    background: #ffffff;
    top: 10px;
    left: 10px;
    padding: 5px 0;
    color: #808080;
    cursor: default;
    border-style: solid;
    border-width: 1px;
    border-color: #7d7d7d;
    z-index: 100;

    &.add-repository {
        position: absolute;
    }

    &:focus {
        outline: none;
    }

    ul {
        list-style: none;
        margin:0;
        padding:0;
        border:0;
    }

    li {
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        &.enabled {
            color: #222222;
            cursor: pointer;
            &:hover {
                background-color: @hover_color;
            }
        }

        &.loading {
            &:after {
                content: '';
                height: 18px;
                width: 18px;
                position: relative;
                left: 10px;
                top: 5px;
                display: inline-block;
                vertical-align: top;
                background: @spinner_black;
                .icon-spin;
            }
        }
        .dropdown-content {
            left: 100%;
            top: 0px;

            &.onLeft {
                left: -0;
                transform: translateX(-100%);
            }
        }
    }
    li:hover .dropdown-content, li:focus .dropdown-content {
        display: block;
    }
}

.dropdown-content {
    span.item-name > .name {
        margin-left: 5px;
    }

    i {
        vertical-align: middle;
        &.img-folder {
            display: inline-block;
            background-image: @repository_icons_black;
            background-repeat: no-repeat;
            background-position: left center;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            background-position: @folder_position;
        }
    }

    display: none;
    position: absolute;
    text-align: left;
    white-space: nowrap;
}

.repositoryNavigator() {
    .header {
        height: 30px;
        line-height: 30px;
        position:relative;

        .multiple-select {
            margin-left: -2px;
            margin-top: 5px;

            i {
                background: @repository_icons_multiselect;
                background-repeat: no-repeat;
                background-size: 72px 18px;
                background-position: 0px 0;
                display: inline-block;
                height: 18px;
                width: 18px;
                &:hover:not(.clicked) {
                    border-radius: 25px;
                    background-color: @selection_color;
                    background-position: -36px 0;
                }
                &.clicked {
                    border-radius: 25px;
                    background-color: @selection_color;
                    background-position: -54px 0;
                }
            }
        }

        span.sortable-header{
            width: 100%;
            i {
                color: #999;
                font-weight: bold;
                cursor: pointer;
                position: absolute;
                right: 10%;
                top: 30%;
                &.fa-sort {
                    opacity: 0.4;
                }
            }
        }
    }

    .details {
        position:relative;
        > span {
            position: absolute;
            .ellipsis;

            &.item-name {
                left: 0%;
            }
            &.lastUpdated {
                left: 33%;
            }
            &.updatedBy {
                left: 66%;
            }
        }
    }

    ul {
        list-style: outside none none;
        padding: 0 0 5px 0;
        width: 100%;
        margin: 0;
        height: 100%;
        overflow-x: auto;
        overflow-y: auto;

        li {
            .itemLi;
            padding-left: 15px;

            &.openSave:not(.saveAs) {
                height: 60px;
                button {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    .primaryButton;
                    padding: 0 15px;
                    height: 28px;
                    line-height: 26px;
                    &.loading:before {
                        top: 4px;
                    }
                }
            }

            &.saveAs {
                height: 34px;
                padding: 3px 0 3px 35px;

                span.item-name.edit {
                    width: 33%;
                }
                span.saveAsBtn {
                    left: 33%;
                    button {
                        .primaryButton;
                        padding: 0 15px;
                        height: 28px;
                        line-height: 26px;
                        display: inline-block;
                        &.loading:before {
                            margin-top: 2px;
                            margin-right: 4px;
                            margin-left: 0;
                        }
                    }
                }
            }

            &.empty {
                text-align: center;
                margin-top: 1em;
            }

            &.item {
                cursor: default;
                position:relative;

                i[class^="img-"] {
                    background-image: @repository_icons_black;
                    background-repeat: no-repeat;
                    background-position: left center;
                    width: 18px;
                    position: absolute;
                    height: 18px;
                    margin-top: 5px;
                    &.img-file {
                        background-position: @file_position;
                    }
                    &.img-folder {
                        background-position: @folder_position;
                    }
                }

                .details {
                    span.item-name > .name {
                        margin-left: 30px;
                    }
                    span.item-name {
                        cursor: pointer;
                    }
                    span.edit:not(.item-name) {
                        margin-left: 30px;
                    }
                    span.edit {
                        display: inline-block;
                        input {
                            width: 99%;
                            padding: 1px 5px;
                            border-width: 1px;
                            border-style: solid;
                            border-color: @normal_color;
                            line-height: 24px;
                            height: 28px;
                            font-size: 1em;
                            &:focus {
                                border: 1px solid @normal_color;
                                outline: none;
                            }
                        }
                    }
                }
                &.loading {
                    i {
                        display: none;
                    }
                    &:after {
                        left: 27px;
                    }
                    &.saveAs:after {
                        background: none;
                    }
                }
            }
        }
    }
}

.repositoryContainer() {
    ul {
        list-style: outside none none;
        padding: 5px 0;
        width: 100%;
        margin: 0;
        position: absolute;
        top: 42px;
        bottom: 0;
        overflow: auto;

        li {
            .itemLi;

            &.item {
                cursor: pointer;
                padding-left: 23px;
                position: relative;

                span.item-name {
                    margin-left: 23px;
                }

                .item-multiple-select {
                    position: absolute;
                    i {
                        height: 20px;
                        width: 20px;
                        margin-top: 6px;
                    }
                }

                i[class^="img-"] {
                    background-image: @repository_icons_black;
                    background-repeat: no-repeat;
                    background-position: left center;
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    margin: 6px 0;

                    &.img-local {
                        background-position: @local_position;
                    }
                    &.img-publicapi {
                        background-position: @public_position;
                    }
                    &.img-group {
                        background-position: @group_position;
                    }
                    &.img-personal {
                        background-position: @personal_position;
                    }
                    &.img-sharepoint {
                        background-position: @sharepoint_position;
                    }
                    &.img-cmis {
                        background-position: @cmis_position;
                    }
                }
            }

            &.add-repository {
                font-size: 26px;
                text-align: center;
                color: #ccc;
                cursor: pointer;

                i {
                    background-image: @repository_add;
                    background-repeat: no-repeat;
                    background-position: left center;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    background-position: 0 0;
                }

                &:hover i {
                    width: 25px;
                    background-position: -24px 0;
                }
            }
        }
    }
}

.itemLi() {
    &.item {
        font-size: 14px;
        height: 28px;
        line-height: 28px;

        .item-multiple-select {
            width: 24px;
            margin-left: -2px;
            height: 28px;
            top: 1px;

            i.multiple-select {
                height: 18px;
                width: 18px;
                margin-top: 5px;
                background: @repository_icons_multiselect;
                background-repeat: no-repeat;
                background-size: 72px 18px;
                display: inline-block;
                background-position: -18px 0;
            }
        }

        &.active {
            background-color: @selection_color;
            color: #222;
            i.multiple-select {
                background-position: -54px 0;
            }
        }

        &:not(.active):hover {
            background-color: @hover_color;
            i.multiple-select{
                background: @repository_icons_multiselect;
                background-repeat: no-repeat;
                background-position: -18px 0;
                background-size: 72px 18px;
                display: inline-block;
                height: 18px;
                width: 18px;
                margin-top: 5px;
                &:hover {
                    background-position: -36px 0;
                }
            }
        }

        &.loading {
            &:after {
                content: '';
                height: 18px;
                width: 18px;
                position: relative;
                left: 10px;
                top: 6px;
                display: inline-block;
                vertical-align: top;
                background: @spinner_black;
                .icon-spin;
            }
        }

        &.loading.active:after {
            background: @spinner_white;
        }
    }
}

.icon-spin() {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/* To override when used inside the products*/
.ribbon-bar .side-panel {
    .box-sizing();
}

.side-panel {
    color: #666666;
    .box-sizing();

    .backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255,255,255,.4);
    }

    .place-container {
        i[class^="img-"] {
            background-image: @repository_icons_black;
            background-repeat: no-repeat;
            background-position: left center;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            position: absolute;

            &.img-local {
                background-position: @local_position;
            }
            &.img-publicapi {
                background-position: @public_position;
            }
            &.img-group {
                background-position: @group_position;
            }
            &.img-personal {
                background-position: @personal_position;
            }
            &.img-sharepoint {
                background-position: @sharepoint_position;
            }
            &.img-cmis {
                background-position: @cmis_position;
            }
        }
        span {
            margin-left: 23px;
        }
    }

    .panel-content {
        width: 350px;
        background-color: #FFFFFF;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        box-shadow: -30px 0 30px -30px rgba(0,0,0,.4);

        .item {
            height: 28px;
            i.img-folder {
                background-image: @repository_icons_black;
                background-repeat: no-repeat;
                background-position: left center;
                width: 18px;
                height: 18px;
                vertical-align: middle;
                background-position: @folder_position;
            }
            span {
                margin-left: 5px;
                vertical-align: middle;
            }
            .ellipsis();
        }

        .panel-header {
            padding: 15px;
            border-bottom: 1px solid #eaeaea;
            .title {
                margin-left: 10px;
                margin-bottom: 10px;
            }
            .label-from, .label-to {
                font-size: 12px;
                font-weight: bold;
            }
            .origin-place {
                margin-left: 10px;
                margin-top: 10px;
                font-size: 12px;
                span {
                    font-weight: 700;
                }
            }
            .origin-path {
                margin: 10px 0 3px 10px;
                font-weight: bold;
                font-size: 12px;
            }
            .selected-element {
                .item {
                    line-height: 28px;
                }
                font-size: 12px;
                margin-left: 10px;
                i {
                    vertical-align: middle;
                    &.img-folder {
                        display: inline-block;
                    }
                }
            }
            .destination-place {
                margin-bottom: 10px;
                font-size: 12px;
                margin-left: 10px;
                .change-place-section {
                    width: 100%;

                    .changeplace {
                        width: 100%;
                        margin-top: 10px;
                        background-color: white;
                        border: solid 1px #ccc;
                        padding: 4px 7px 4px 10px;
                        box-shadow: none;
                        .current-place {
                            float: left;
                            margin-left: 23px;
                            word-wrap: break-word;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-y: hidden;
                            max-width: 85%;
                            overflow-x: hidden;

                            &.select-place {
                                color: #999;
                                margin-left: 0px;
                            }
                        }
                        .caret {
                            float: right;
                            margin-top: 7px;
                        }
                        .repository-icon {
                            float: left;
                        }

                    }

                    .dropdown-menu {
                        width: 100%;
                        max-width: 320px;
                        font-size: 12px;

                        li {
                            a {
                                word-wrap: break-word;
                                overflow-x: hidden;
                                overflow-y: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding: 4px 10px;

                                i[class^="img-"] {
                                    background-image: @repository_icons_black;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    width: 18px;
                                    height: 18px;
                                    vertical-align: middle;
                                    position: absolute;

                                    &.img-local {
                                        background-position: @local_position;
                                    }
                                    &.img-publicapi {
                                        background-position: @public_position;
                                    }
                                    &.img-group {
                                        background-position: @group_position;
                                    }
                                    &.img-personal {
                                        background-position: @personal_position;
                                    }
                                    &.img-sharepoint {
                                        background-position: @sharepoint_position;
                                    }
                                    &.img-cmis {
                                        background-position: @cmis_position;
                                    }
                                }

                                span {
                                    margin-left: 23px;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }

            hr {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .places-selection {
                margin: 4px 0 8px 10px;
                #select-place {
                    font-size: 12px;
                }
            }
            .locationBar {
                margin-left: 10px;
                font-size: 12px;
                display: inline-block;
                word-wrap: break-word;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-y: hidden;
                overflow-x: hidden;
                width: 300px;

                .path-element {
                    background: @repository_icons_black;
                    background-repeat: no-repeat;
                    background-size: 120px 10px;
                    display: inline-block;
                    height: 10px;
                    margin-left: 3px;
                    margin-right: 3px;
                    width: 10px;
                }
                .path-back {
                    cursor: pointer;
                    background-position: -110px;
                }
                span.path-separator {
                    background-position: @caret_right * 10 / 18;
                    margin-bottom: 3px;
                }
                .path {
                    cursor: pointer;
                }
                :last-child {
                    font-weight: bold;
                }
                span.short-path {
                    max-width: 80px;
                    height: 14px;
                    word-wrap: break-word;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow-y: hidden;
                    overflow-x: hidden;
                }                   
            }
            .origin-path {
                .path-element {
                    background: @repository_icons_black;
                    background-repeat: no-repeat;
                    background-size: 120px 10px;
                    display: inline-block;
                    height: 10px;
                    margin-left: 3px;
                    margin-right: 3px;
                    width: 10px;
                }
                span.path-separator {
                    background-position: @caret_right * 10 / 18;
                    margin-bottom: 3px;
                }
            }
        }

        .panel-body {
            border-bottom: 1px solid #eaeaea;

            .destination-header {
                margin-bottom: 10px;
                font-size: 12px;
                margin-left: 10px;
            }

            .item {
                cursor: pointer;
                i {
                    display: inline-block;
                }
            }
            .repository-content {
                margin-bottom: 10px;
                margin-left: 10px;
                margin-right: 10px;
                .folder {
                    &:hover {
                        background-color: @hover_color;
                    }
                }
                div.content {
                    height: 28px;
                    line-height: 28px;
                    padding-left: 5px;
                }
                div.empty {
                    margin-top: 10px;
                    height: 28px;
                    line-height: 28px;
                }
                #add-new-folder, #add-new-foldername {
                    cursor: pointer;
                    span, input {
                        margin-left: 5px;
                    }
                    span {
                        vertical-align: middle;
                    }
                    input {
                        width: 200px;
                    }
                }
                #add-new-foldername {
                    display: none;
                    input {
                        height: 28px;
                        line-height: 24px;
                        padding: 5px;
                        border: 1px solid @normal_color;
                        font-size: 1em;
                        &:focus {
                            outline: none;
                            outline-style: none;
                        }
                    }
                }
                #add-new-folder .new-folder {
                    vertical-align: middle;
                }

                .new-folder {
                    background: @repository_icons_black;
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 18px;
                    width: 18px;
                    background-position: @newFolder_position;
                    vertical-align: middle;
                }
            }
        }

        .footer {
            padding: 15px;
            button {
                .primaryButton();
                border-radius: 0;
                margin-left: 10px;
                margin-top: 15px;
                color: white;
                background-color: @normal_color;
                &:hover {
                    background-color: @hover_color;
                }
                &.disabled {
                    color: #333;
                    background-color: #fff;
                    border-color: #ababab;
            }
        }
        }
        .title {
            font-size: 16px;
            font-weight: bold;
        }

        button.close {
            .sideBarTopButton();
        }
    }
}

.tox.tox-tinymce {
    z-index: 1060;
}

.side-panel.side-panel-issue-editor {

    .panel-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button.showDescription {
            .sideBarTopButton();
            right: 28px;
            top: 19px;
            font-size: 15px;
            &.toggled {
                color: @normal_color;
                opacity: 1;
            }
        }

        button.exportCSV {
            .sideBarTopButton();
            right: 50px;
            top: 19px;
            font-size: 15px;
        }
        .panel-header {
            flex-grow: 0;
            flex-shrink: 0;
            height: auto;
        }
        .panel-body {
            padding: 2px;
            flex: 1;
            height: 80%;
            #issueEditorPlugin {
                height: 100%;
            }
        }
    }
}

.side-panel.side-panel-restore-files {
    .panel-content {
        width: 800px;
        .panel-header {
            height: 130px;
            min-height: 125px;
            .place-restore-item {
                margin-left: 10px;
                margin-top: 30px;
                i[class^="img-"] {
                    background-image: @repository_icons_black;
                    background-repeat: no-repeat;
                    background-position: left center;
                    width: 18px;
                    height: 18px;
                    vertical-align: middle;
                    position: absolute;

                    &.img-local {
                        background-position: @local_position;
                    }
                    &.img-publicapi {
                        background-position: @public_position;
                    }
                    &.img-group {
                        background-position: @group_position;
                    }
                    &.img-personal {
                        background-position: @personal_position;
                    }
                    &.img-sharepoint {
                        background-position: @sharepoint_position;
                    }
                    &.img-cmis {
                        background-position: @cmis_position;
                    }
                }
                span {
                    margin-left: 23px;
                }
            }

            .content-header {
                margin: 10px 12px;
            }
        }
        .panel-body {
            max-height: 80%;
            overflow-y: auto;
            .content-header {
                margin-left: 10px;
            }
            ul {
                list-style: outside none none;
                overflow-x: auto;
                overflow-y: auto;
                padding-left: 15px;

                .header {
                    height: 30px;
                    line-height: 30px;
                    position:relative;
                    padding-left: 10px;

                    .details {
                        position:relative;
                        > span {
                            position: absolute;

                            &.name{
                                left: 4%;
                                cursor: default;
                            }
                            &.path {
                                left: 40%;
                                cursor: default;
                            }
                            &.deleted-date {
                                left: 75%;
                                cursor: default;
                            }
                        }
                    }

                    .multiple-select {
                        margin-left: -2px;
                        margin-top: 5px;
                        cursor: pointer;

                        i {
                            background: @repository_icons_multiselect;
                            background-repeat: no-repeat;
                            background-size: 72px 18px;
                            background-position: 0px 0;
                            display: inline-block;
                            height: 18px;
                            width: 18px;
                            &:hover:not(.clicked) {
                                border-radius: 25px;
                                background-color: @selection_color;
                                background-position: -36px 0;
                            }
                            &.clicked {
                                border-radius: 25px;
                                background-color: @selection_color;
                                background-position: -54px 0;
                            }
                        }
                    }
                }

                .empty {
                    margin-top: 20px;
                    text-align: center;
                }

                .item {
                    font-size: 14px;
                    height: 28px;
                    line-height: 28px;
                    padding-left: 10px;

                    .item-multiple-select {
                        width: 24px;
                        margin-left: -2px;
                        height: 28px;
                        top: 1px;

                        i.multiple-select {
                            height: 18px;
                            width: 18px;
                            margin-top: 5px;
                            background: @repository_icons_multiselect;
                            background-repeat: no-repeat;
                            background-size: 72px 18px;
                            display: inline-block;
                            background-position: -18px 0;
                        }
                    }

                    &.active {
                        background-color: @selection_color;
                        color: #222;
                        i.multiple-select {
                            background-position: -54px 0;
                        }
                    }

                    &:not(.active):hover {
                        background-color: @hover_color;
                        i.multiple-select{
                            background: @repository_icons_multiselect;
                            background-repeat: no-repeat;
                            background-position: -18px 0;
                            background-size: 72px 18px;
                            display: inline-block;
                            height: 18px;
                            width: 18px;
                            margin-top: 5px;
                            &:hover {
                                background-position: -36px 0;
                            }
                        }
                    }

                    .details {
                        position:relative;
                        > span {
                            position: absolute;
                            .ellipsis;
                            margin-left: 0px;

                            &.item-name{
                                left: 4%;
                                max-width: 32%;
                                .name {
                                    vertical-align: top;
                                    margin-left: 10px;
                                }
                            }
                            &.item-path {
                                left: 40%;
                                max-width: 30%;
                            }
                            &.item-deleted-date {
                                left: 75%;
                                max-width: 15%;
                            }
                        }
                    }
                }
            }
        }
        .footer{
            button {
                margin: auto;
            }
        }
    }
}

.side-panel.side-panel-promote-place {
    .panel-content {
        i[class^="img-"] {
            background-image: @repository_icons_black;
            background-repeat: no-repeat;
            background-position: left center;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            position: absolute;

            &.img-local {
                background-position: @local_position;
            }
            &.img-publicapi {
                background-position: @public_position;
            }
            &.img-group {
                background-position: @group_position;
            }
            &.img-personal {
                background-position: @personal_position;
            }
            &.img-sharepoint {
                background-position: @sharepoint_position;
            }
            &.img-cmis {
                background-position: @cmis_position;
            }
        }
        .panel-header {
            span {
                margin-left: 23px;
            }

            .content-header {
                margin: 10px 12px;
            }

            .description {
                font-size: 12px;
                margin: 10px 10px 15px 5px;
            }
        }
        .panel-body {
            .label {
                font-size: 12px;
                font-weight: bold;
                color: inherit;
            }
            .target-place {
                margin-bottom: 10px;
                font-size: 12px;
                margin-left: 10px;
                .change-place-section {
                    width: 100%;

                    .changeplace {
                        width: 100%;
                        margin-top: 10px;
                        background-color: white;
                        border: solid 1px #ccc;
                        padding: 4px 7px 4px 10px;
                        box-shadow: none;
                        .current-place {
                            float: left;
                            margin-left: 23px;
                            word-wrap: break-word;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-y: hidden;
                            max-width: 85%;
                            overflow-x: hidden;

                            &.select-place {
                                color: #999;
                                margin-left: 0px;
                            }
                        }
                        .caret {
                            float: right;
                            margin-top: 7px;
                        }
                        .repository-icon {
                            float: left;
                        }

                    }

                    .dropdown-menu {
                        width: 100%;
                        max-width: 320px;
                        font-size: 12px;

                        li {
                            a {
                                word-wrap: break-word;
                                overflow-x: hidden;
                                overflow-y: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding: 4px 10px;

                                span {
                                    margin-left: 23px;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
            .state {
                margin-bottom: 10px;
                font-size: 12px;
                margin-left: 10px;
                .state-section {
                    width: 100%;

                    .state-btn {
                        width: 100%;
                        margin-top: 10px;
                        background-color: white;
                        border: solid 1px #ccc;
                        padding: 4px 7px 4px 10px;
                        box-shadow: none;
                        .current-state {
                            float: left;
                            word-wrap: break-word;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-y: hidden;
                            max-width: 85%;
                            overflow-x: hidden;

                            &.select-state{
                                color: #999;
                                margin-left: 0px;
                            }
                        }
                        .caret {
                            float: right;
                            margin-top: 7px;
                        }

                    }

                    .dropdown-menu {
                        width: 100%;
                        max-width: 320px;
                        font-size: 12px;

                        li {
                            a {
                                word-wrap: break-word;
                                overflow-x: hidden;
                                overflow-y: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding: 4px 10px;

                                span {
                                    margin-left: 23px;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
            .version {
                display: flex;
            }
            input {
                margin: 10px 0 10px 10px;
            }
        }
        .footer{
            button {
                margin: auto;
            }
        }
    }
}
