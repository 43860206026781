/*
 * Utility functions
 */
/*
 * Message Plugin
 */
.user-message-background {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #cccccc;
  opacity: 0.5;
  z-index: 50;
}
.user-message-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  z-index: 100;
}
.user-message-container .user-message {
  position: relative;
  margin: 30px auto;
}
.user-message {
  background-color: #FFFFFF;
  position: relative;
  margin: 30px auto;
  width: 315px;
  border: 1px solid #7d7d7d;
  line-height: 20px;
  font-size: 14px;
  z-index: 100;
}
.user-message .user-message-title {
  border-bottom: 1px solid #e1e1e1;
  padding: 15px;
  font-weight: bold;
  color: #009eb6;
}
.user-message .user-message-message {
  padding: 15px;
  margin-bottom: 10px;
}
.user-message button {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
}
.user-message button.enabled {
  cursor: pointer;
  color: #333;
}
.user-message button.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.user-message button + button {
  margin-left: 5px;
}
.user-message button.loading {
  padding-left: 10px;
}
.user-message button.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.user-message .user-message-buttons {
  text-align: right;
  padding: 15px;
  border-top: 1px solid #e1e1e1;
}
.user-message .user-message-buttons button:first-child {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
}
.user-message .user-message-buttons button:first-child.enabled {
  cursor: pointer;
  color: #333;
}
.user-message .user-message-buttons button:first-child.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.user-message .user-message-buttons button:first-child + button {
  margin-left: 5px;
}
.user-message .user-message-buttons button:first-child.loading {
  padding-left: 10px;
}
.user-message .user-message-buttons button:first-child.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.user-message .user-message-buttons button:first-child.enabled {
  background-color: #009eb6;
  color: #fff;
}
.user-message .user-message-buttons button:first-child.loading:before {
  background: url("../images/spinner_white.png");
}
/*
 * Share modal
 */
.share,
.user-message.invite {
  width: 700px;
}
.share,
.user-message.invite,
.share *,
.user-message.invite *,
.share *:before,
.user-message.invite *:before,
.share *:after,
.user-message.invite *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.share label,
.user-message.invite label {
  font-weight: bold !important;
}
.share #global-access,
.user-message.invite #global-access {
  list-style: none;
  padding: 0;
}
.share #global-access li.user-access,
.user-message.invite #global-access li.user-access {
  padding-top: 10px;
}
.share .global-access,
.user-message.invite .global-access {
  padding: 15px;
}
.share .global-access.admin .global-access-right,
.user-message.invite .global-access.admin .global-access-right {
  cursor: pointer;
}
.share .global-access.admin i,
.user-message.invite .global-access.admin i {
  background: url('../images/repository_share.png');
  background-repeat: no-repeat;
  display: inline-block;
  height: 14px;
  width: 19px;
  vertical-align: middle;
}
.share .global-access.admin i.caret-down,
.user-message.invite .global-access.admin i.caret-down {
  width: 8px;
  background-position: -11px 0;
  margin-left: 2px;
  color: #333;
}
.share .global-access .global-access-right,
.user-message.invite .global-access .global-access-right {
  margin-right: 20px;
}
.share .global-access .caret-down,
.user-message.invite .global-access .caret-down {
  display: none;
}
.share .global-access span,
.user-message.invite .global-access span {
  display: inline-block;
}
.share .global-access span.left,
.user-message.invite .global-access span.left {
  width: 80%;
}
.share .global-access span.right,
.user-message.invite .global-access span.right {
  width: 20%;
  text-align: right;
}
.share .bottom-border,
.user-message.invite .bottom-border {
  border-bottom: 1px solid #ebebeb;
}
.share .bottom-border.row,
.user-message.invite .bottom-border.row {
  padding: 15px;
}
.share .user-message-title.loading:after,
.user-message.invite .user-message-title.loading:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 0px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-title button.close,
.user-message.invite .user-message-title button.close {
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  opacity: 0.2;
  text-shadow: 0 1px 0 #fff;
  border: none;
}
.share .user-message-title button.close:hover,
.user-message.invite .user-message-title button.close:hover {
  opacity: 0.5;
}
.share .user-message-message,
.user-message.invite .user-message-message {
  padding: 15px;
  min-height: 200px;
}
.share .user-message-message.read-only i,
.user-message.invite .user-message-message.read-only i,
.share .user-message-message.read-only .invite,
.user-message.invite .user-message-message.read-only .invite {
  display: none !important;
}
.share .user-message-message.read-only .r-message,
.user-message.invite .user-message-message.read-only .r-message {
  margin-top: 15px;
}
.share .user-message-message.read-only .users li span.user-rights,
.user-message.invite .user-message-message.read-only .users li span.user-rights {
  cursor: not-allowed;
}
.share .user-message-message ul.users,
.user-message.invite .user-message-message ul.users,
.share .user-message-message ul.groups,
.user-message.invite .user-message-message ul.groups {
  list-style: outside none none;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  margin: 0;
}
.share .user-message-message ul.users li,
.user-message.invite .user-message-message ul.users li,
.share .user-message-message ul.groups li,
.user-message.invite .user-message-message ul.groups li {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
}
.share .user-message-message ul.users li span,
.user-message.invite .user-message-message ul.users li span,
.share .user-message-message ul.groups li span,
.user-message.invite .user-message-message ul.groups li span {
  display: inline-block;
}
.share .user-message-message ul.users li span.left,
.user-message.invite .user-message-message ul.users li span.left,
.share .user-message-message ul.groups li span.left,
.user-message.invite .user-message-message ul.groups li span.left {
  width: 80%;
}
.share .user-message-message ul.users li span.right,
.user-message.invite .user-message-message ul.users li span.right,
.share .user-message-message ul.groups li span.right,
.user-message.invite .user-message-message ul.groups li span.right {
  width: 20%;
  text-align: right;
}
.share .user-message-message ul.users li span.right i,
.user-message.invite .user-message-message ul.users li span.right i,
.share .user-message-message ul.groups li span.right i,
.user-message.invite .user-message-message ul.groups li span.right i {
  background: url('../images/repository_share.png');
  background-repeat: no-repeat;
  display: inline-block;
  height: 14px;
  width: 19px;
  vertical-align: middle;
}
.share .user-message-message ul.users li span.right i.caret-down,
.user-message.invite .user-message-message ul.users li span.right i.caret-down,
.share .user-message-message ul.groups li span.right i.caret-down,
.user-message.invite .user-message-message ul.groups li span.right i.caret-down {
  width: 8px;
  background-position: -11px 0;
}
.share .user-message-message ul.users li span.right i.remove,
.user-message.invite .user-message-message ul.users li span.right i.remove,
.share .user-message-message ul.groups li span.right i.remove,
.user-message.invite .user-message-message ul.groups li span.right i.remove {
  width: 11px;
}
.share .user-message-message ul.users li span.right .user-rights,
.user-message.invite .user-message-message ul.users li span.right .user-rights,
.share .user-message-message ul.groups li span.right .user-rights,
.user-message.invite .user-message-message ul.groups li span.right .user-rights {
  padding: 0 5px;
  cursor: pointer;
  margin-right: 7px;
}
.share .user-message-message ul.users li span.right .remove,
.user-message.invite .user-message-message ul.users li span.right .remove,
.share .user-message-message ul.groups li span.right .remove,
.user-message.invite .user-message-message ul.groups li span.right .remove {
  cursor: pointer;
}
.share .user-message-message ul.users li span.r-message,
.user-message.invite .user-message-message ul.users li span.r-message,
.share .user-message-message ul.groups li span.r-message,
.user-message.invite .user-message-message ul.groups li span.r-message {
  display: none;
  margin-left: 5px;
}
.share .user-message-message ul.users li.loading .user-email:after,
.user-message.invite .user-message-message ul.users li.loading .user-email:after,
.share .user-message-message ul.groups li.loading .user-email:after,
.user-message.invite .user-message-message ul.groups li.loading .user-email:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 3px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-message ul.users li.invite,
.user-message.invite .user-message-message ul.users li.invite,
.share .user-message-message ul.groups li.invite,
.user-message.invite .user-message-message ul.groups li.invite {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: none;
}
.share .user-message-message ul.users li.invite .user-email,
.user-message.invite .user-message-message ul.users li.invite .user-email,
.share .user-message-message ul.groups li.invite .user-email,
.user-message.invite .user-message-message ul.groups li.invite .user-email {
  width: 50%;
}
.share .user-message-message ul.users li.invite .user-email input,
.user-message.invite .user-message-message ul.users li.invite .user-email input,
.share .user-message-message ul.groups li.invite .user-email input,
.user-message.invite .user-message-message ul.groups li.invite .user-email input {
  width: 99%;
  padding: 1px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #009eb6;
  line-height: 24px;
  height: 28px;
  font-size: 1em;
}
.share .user-message-message ul.users li.invite .user-email input:focus,
.user-message.invite .user-message-message ul.users li.invite .user-email input:focus,
.share .user-message-message ul.groups li.invite .user-email input:focus,
.user-message.invite .user-message-message ul.groups li.invite .user-email input:focus {
  border: 1px solid #009eb6;
  outline: none;
}
.share .user-message-message ul.users li.invite button.addUser,
.user-message.invite .user-message-message ul.users li.invite button.addUser,
.share .user-message-message ul.groups li.invite button.addUser,
.user-message.invite .user-message-message ul.groups li.invite button.addUser,
.share .user-message-message ul.users li.invite button.addMember,
.user-message.invite .user-message-message ul.users li.invite button.addMember,
.share .user-message-message ul.groups li.invite button.addMember,
.user-message.invite .user-message-message ul.groups li.invite button.addMember {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
  line-height: 22px;
  display: inline-block;
}
.share .user-message-message ul.users li.invite button.addUser.enabled,
.user-message.invite .user-message-message ul.users li.invite button.addUser.enabled,
.share .user-message-message ul.groups li.invite button.addUser.enabled,
.user-message.invite .user-message-message ul.groups li.invite button.addUser.enabled,
.share .user-message-message ul.users li.invite button.addMember.enabled,
.user-message.invite .user-message-message ul.users li.invite button.addMember.enabled,
.share .user-message-message ul.groups li.invite button.addMember.enabled,
.user-message.invite .user-message-message ul.groups li.invite button.addMember.enabled {
  cursor: pointer;
  color: #333;
}
.share .user-message-message ul.users li.invite button.addUser.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.users li.invite button.addUser.enabled:hover:not(.loading),
.share .user-message-message ul.groups li.invite button.addUser.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.groups li.invite button.addUser.enabled:hover:not(.loading),
.share .user-message-message ul.users li.invite button.addMember.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.users li.invite button.addMember.enabled:hover:not(.loading),
.share .user-message-message ul.groups li.invite button.addMember.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.groups li.invite button.addMember.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.share .user-message-message ul.users li.invite button.addUser + button,
.user-message.invite .user-message-message ul.users li.invite button.addUser + button,
.share .user-message-message ul.groups li.invite button.addUser + button,
.user-message.invite .user-message-message ul.groups li.invite button.addUser + button,
.share .user-message-message ul.users li.invite button.addMember + button,
.user-message.invite .user-message-message ul.users li.invite button.addMember + button,
.share .user-message-message ul.groups li.invite button.addMember + button,
.user-message.invite .user-message-message ul.groups li.invite button.addMember + button {
  margin-left: 5px;
}
.share .user-message-message ul.users li.invite button.addUser.loading,
.user-message.invite .user-message-message ul.users li.invite button.addUser.loading,
.share .user-message-message ul.groups li.invite button.addUser.loading,
.user-message.invite .user-message-message ul.groups li.invite button.addUser.loading,
.share .user-message-message ul.users li.invite button.addMember.loading,
.user-message.invite .user-message-message ul.users li.invite button.addMember.loading,
.share .user-message-message ul.groups li.invite button.addMember.loading,
.user-message.invite .user-message-message ul.groups li.invite button.addMember.loading {
  padding-left: 10px;
}
.share .user-message-message ul.users li.invite button.addUser.loading:before,
.user-message.invite .user-message-message ul.users li.invite button.addUser.loading:before,
.share .user-message-message ul.groups li.invite button.addUser.loading:before,
.user-message.invite .user-message-message ul.groups li.invite button.addUser.loading:before,
.share .user-message-message ul.users li.invite button.addMember.loading:before,
.user-message.invite .user-message-message ul.users li.invite button.addMember.loading:before,
.share .user-message-message ul.groups li.invite button.addMember.loading:before,
.user-message.invite .user-message-message ul.groups li.invite button.addMember.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-message ul.users li.invite button.addUser.enabled,
.user-message.invite .user-message-message ul.users li.invite button.addUser.enabled,
.share .user-message-message ul.groups li.invite button.addUser.enabled,
.user-message.invite .user-message-message ul.groups li.invite button.addUser.enabled,
.share .user-message-message ul.users li.invite button.addMember.enabled,
.user-message.invite .user-message-message ul.users li.invite button.addMember.enabled,
.share .user-message-message ul.groups li.invite button.addMember.enabled,
.user-message.invite .user-message-message ul.groups li.invite button.addMember.enabled {
  background-color: #009eb6;
  color: #fff;
}
.share .user-message-message ul.users li.invite button.addUser.loading:before,
.user-message.invite .user-message-message ul.users li.invite button.addUser.loading:before,
.share .user-message-message ul.groups li.invite button.addUser.loading:before,
.user-message.invite .user-message-message ul.groups li.invite button.addUser.loading:before,
.share .user-message-message ul.users li.invite button.addMember.loading:before,
.user-message.invite .user-message-message ul.users li.invite button.addMember.loading:before,
.share .user-message-message ul.groups li.invite button.addMember.loading:before,
.user-message.invite .user-message-message ul.groups li.invite button.addMember.loading:before {
  background: url("../images/spinner_white.png");
}
.share .user-message-message ul.users li button.invite,
.user-message.invite .user-message-message ul.users li button.invite,
.share .user-message-message ul.groups li button.invite,
.user-message.invite .user-message-message ul.groups li button.invite {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
}
.share .user-message-message ul.users li button.invite.enabled,
.user-message.invite .user-message-message ul.users li button.invite.enabled,
.share .user-message-message ul.groups li button.invite.enabled,
.user-message.invite .user-message-message ul.groups li button.invite.enabled {
  cursor: pointer;
  color: #333;
}
.share .user-message-message ul.users li button.invite.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.users li button.invite.enabled:hover:not(.loading),
.share .user-message-message ul.groups li button.invite.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.groups li button.invite.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.share .user-message-message ul.users li button.invite + button,
.user-message.invite .user-message-message ul.users li button.invite + button,
.share .user-message-message ul.groups li button.invite + button,
.user-message.invite .user-message-message ul.groups li button.invite + button {
  margin-left: 5px;
}
.share .user-message-message ul.users li button.invite.loading,
.user-message.invite .user-message-message ul.users li button.invite.loading,
.share .user-message-message ul.groups li button.invite.loading,
.user-message.invite .user-message-message ul.groups li button.invite.loading {
  padding-left: 10px;
}
.share .user-message-message ul.users li button.invite.loading:before,
.user-message.invite .user-message-message ul.users li button.invite.loading:before,
.share .user-message-message ul.groups li button.invite.loading:before,
.user-message.invite .user-message-message ul.groups li button.invite.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-message ul.users li button.invite.enabled,
.user-message.invite .user-message-message ul.users li button.invite.enabled,
.share .user-message-message ul.groups li button.invite.enabled,
.user-message.invite .user-message-message ul.groups li button.invite.enabled {
  background-color: #009eb6;
  color: #fff;
}
.share .user-message-message ul.users li button.invite.loading:before,
.user-message.invite .user-message-message ul.users li button.invite.loading:before,
.share .user-message-message ul.groups li button.invite.loading:before,
.user-message.invite .user-message-message ul.groups li button.invite.loading:before {
  background: url("../images/spinner_white.png");
}
.share .user-message-message ul.users li button.share-groups,
.user-message.invite .user-message-message ul.users li button.share-groups,
.share .user-message-message ul.groups li button.share-groups,
.user-message.invite .user-message-message ul.groups li button.share-groups {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
}
.share .user-message-message ul.users li button.share-groups.enabled,
.user-message.invite .user-message-message ul.users li button.share-groups.enabled,
.share .user-message-message ul.groups li button.share-groups.enabled,
.user-message.invite .user-message-message ul.groups li button.share-groups.enabled {
  cursor: pointer;
  color: #333;
}
.share .user-message-message ul.users li button.share-groups.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.users li button.share-groups.enabled:hover:not(.loading),
.share .user-message-message ul.groups li button.share-groups.enabled:hover:not(.loading),
.user-message.invite .user-message-message ul.groups li button.share-groups.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.share .user-message-message ul.users li button.share-groups + button,
.user-message.invite .user-message-message ul.users li button.share-groups + button,
.share .user-message-message ul.groups li button.share-groups + button,
.user-message.invite .user-message-message ul.groups li button.share-groups + button {
  margin-left: 5px;
}
.share .user-message-message ul.users li button.share-groups.loading,
.user-message.invite .user-message-message ul.users li button.share-groups.loading,
.share .user-message-message ul.groups li button.share-groups.loading,
.user-message.invite .user-message-message ul.groups li button.share-groups.loading {
  padding-left: 10px;
}
.share .user-message-message ul.users li button.share-groups.loading:before,
.user-message.invite .user-message-message ul.users li button.share-groups.loading:before,
.share .user-message-message ul.groups li button.share-groups.loading:before,
.user-message.invite .user-message-message ul.groups li button.share-groups.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-message ul.users li button.share-groups.enabled,
.user-message.invite .user-message-message ul.users li button.share-groups.enabled,
.share .user-message-message ul.groups li button.share-groups.enabled,
.user-message.invite .user-message-message ul.groups li button.share-groups.enabled {
  background-color: #009eb6;
  color: #fff;
}
.share .user-message-message ul.users li button.share-groups.loading:before,
.user-message.invite .user-message-message ul.users li button.share-groups.loading:before,
.share .user-message-message ul.groups li button.share-groups.loading:before,
.user-message.invite .user-message-message ul.groups li button.share-groups.loading:before {
  background: url("../images/spinner_white.png");
}
.share .user-message-message.members button.invite,
.user-message.invite .user-message-message.members button.invite,
.share .user-message-message.groups button.invite,
.user-message.invite .user-message-message.groups button.invite {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
  margin-top: 10px;
}
.share .user-message-message.members button.invite.enabled,
.user-message.invite .user-message-message.members button.invite.enabled,
.share .user-message-message.groups button.invite.enabled,
.user-message.invite .user-message-message.groups button.invite.enabled {
  cursor: pointer;
  color: #333;
}
.share .user-message-message.members button.invite.enabled:hover:not(.loading),
.user-message.invite .user-message-message.members button.invite.enabled:hover:not(.loading),
.share .user-message-message.groups button.invite.enabled:hover:not(.loading),
.user-message.invite .user-message-message.groups button.invite.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.share .user-message-message.members button.invite + button,
.user-message.invite .user-message-message.members button.invite + button,
.share .user-message-message.groups button.invite + button,
.user-message.invite .user-message-message.groups button.invite + button {
  margin-left: 5px;
}
.share .user-message-message.members button.invite.loading,
.user-message.invite .user-message-message.members button.invite.loading,
.share .user-message-message.groups button.invite.loading,
.user-message.invite .user-message-message.groups button.invite.loading {
  padding-left: 10px;
}
.share .user-message-message.members button.invite.loading:before,
.user-message.invite .user-message-message.members button.invite.loading:before,
.share .user-message-message.groups button.invite.loading:before,
.user-message.invite .user-message-message.groups button.invite.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-message.members button.invite.enabled,
.user-message.invite .user-message-message.members button.invite.enabled,
.share .user-message-message.groups button.invite.enabled,
.user-message.invite .user-message-message.groups button.invite.enabled {
  background-color: #009eb6;
  color: #fff;
}
.share .user-message-message.members button.invite.loading:before,
.user-message.invite .user-message-message.members button.invite.loading:before,
.share .user-message-message.groups button.invite.loading:before,
.user-message.invite .user-message-message.groups button.invite.loading:before {
  background: url("../images/spinner_white.png");
}
.share .user-message-message.members button.share-groups,
.user-message.invite .user-message-message.members button.share-groups,
.share .user-message-message.groups button.share-groups,
.user-message.invite .user-message-message.groups button.share-groups {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
  margin-top: 10px;
}
.share .user-message-message.members button.share-groups.enabled,
.user-message.invite .user-message-message.members button.share-groups.enabled,
.share .user-message-message.groups button.share-groups.enabled,
.user-message.invite .user-message-message.groups button.share-groups.enabled {
  cursor: pointer;
  color: #333;
}
.share .user-message-message.members button.share-groups.enabled:hover:not(.loading),
.user-message.invite .user-message-message.members button.share-groups.enabled:hover:not(.loading),
.share .user-message-message.groups button.share-groups.enabled:hover:not(.loading),
.user-message.invite .user-message-message.groups button.share-groups.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.share .user-message-message.members button.share-groups + button,
.user-message.invite .user-message-message.members button.share-groups + button,
.share .user-message-message.groups button.share-groups + button,
.user-message.invite .user-message-message.groups button.share-groups + button {
  margin-left: 5px;
}
.share .user-message-message.members button.share-groups.loading,
.user-message.invite .user-message-message.members button.share-groups.loading,
.share .user-message-message.groups button.share-groups.loading,
.user-message.invite .user-message-message.groups button.share-groups.loading {
  padding-left: 10px;
}
.share .user-message-message.members button.share-groups.loading:before,
.user-message.invite .user-message-message.members button.share-groups.loading:before,
.share .user-message-message.groups button.share-groups.loading:before,
.user-message.invite .user-message-message.groups button.share-groups.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.share .user-message-message.members button.share-groups.enabled,
.user-message.invite .user-message-message.members button.share-groups.enabled,
.share .user-message-message.groups button.share-groups.enabled,
.user-message.invite .user-message-message.groups button.share-groups.enabled {
  background-color: #009eb6;
  color: #fff;
}
.share .user-message-message.members button.share-groups.loading:before,
.user-message.invite .user-message-message.members button.share-groups.loading:before,
.share .user-message-message.groups button.share-groups.loading:before,
.user-message.invite .user-message-message.groups button.share-groups.loading:before {
  background: url("../images/spinner_white.png");
}
/*
 * Share modal
 */
.versionModal {
  width: 700px;
}
.versionModal,
.versionModal *,
.versionModal *:before,
.versionModal *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.versionModal .user-message-title.loading:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 0px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.versionModal .user-message-title button.close {
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  opacity: 0.2;
  text-shadow: 0 1px 0 #fff;
  border: none;
}
.versionModal .user-message-title button.close:hover {
  opacity: 0.5;
}
.versionModal .user-message-message {
  padding: 15px;
}
.versionModal .user-message-message ul.versions {
  list-style: outside none none;
  padding: 0;
  width: 100%;
  margin: 0;
}
.versionModal .user-message-message ul.versions li {
  position: relative;
  padding: 0 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.1em;
  height: 28px;
  line-height: 28px;
}
.versionModal .user-message-message ul.versions li span {
  position: absolute;
  display: inline-block;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
.versionModal .user-message-message ul.versions li.title {
  border-bottom: 1px solid #ebebeb;
}
.versionModal .user-message-message ul.versions li.item.current {
  border: 1px solid #b3e2e9;
}
.versionModal .user-message-message ul.versions li.item:hover {
  background-color: #80cfdb;
  cursor: pointer;
}
.versionModal .user-message-message ul.versions li.item.active {
  background-color: #b3e2e9;
}
.versionModal .user-message-message ul.versions li.noVersion {
  margin-top: 10px;
  border: none;
}
/*
 * Invite Modal
 */
.user-message.invite .user-message-message .step1 ul.users {
  overflow: visible;
}
.user-message.invite .user-message-message .step1 ul.users .item {
  padding-left: 4px;
  padding-right: 4px;
}
.user-message.invite .user-message-message .step1 ul.users .item .left,
.user-message.invite .user-message-message .step1 ul.users .item .right {
  line-height: 18px;
  vertical-align: middle;
}
.user-message.invite .user-message-message .step1 ul.users .item .left span,
.user-message.invite .user-message-message .step1 ul.users .item .right span {
  vertical-align: middle;
}
.user-message.invite .user-message-message .step1 ul.users .item span.icon {
  display: inline-block;
  background-size: 18px 18px;
  background-origin: 2px 0;
  background-image: url('../images/warning_outline.png');
  background-repeat: no-repeat;
  margin-right: 4px;
  width: 18px;
  height: 20px;
}
.user-message.invite .user-message-message .step1 ul.users .item span.user-name {
  margin-right: 3px;
}
.user-message.invite .user-message-message .step1 ul.users .item.warning {
  background-color: #fcf8e3;
}
.user-message.invite .user-message-message .step1 ul.users .item.error {
  background-color: #f2dede;
}
.user-message.invite .user-message-message .step1 ul.users .item.invite > * {
  vertical-align: middle;
}
.user-message.invite .user-message-message .step2 .email_subject {
  width: 100%;
  padding: 5px;
}
.user-message.invite .user-message-message .step2 .email_message {
  margin: 5px 0;
  width: 100%;
  height: 200px;
  padding: 5px;
}
.user-message.invite .user-message-message .step2 .email_confirmation {
  margin-top: 5px;
  margin-right: 3px;
}
/*
 * Repository
 */
div.repository-navigator {
  font-size: 14px;
}
div.repository-navigator,
div.repository-navigator *,
div.repository-navigator *:before,
div.repository-navigator *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.repository-navigator div.repository-navigator-commandbar {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 40px;
  padding-left: 30%;
  background: #009eb6;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.repository-navigator div.repository-navigator-commandbar button {
  position: relative;
  border: none;
  height: 100%;
  background-color: #009eb6;
  cursor: default;
  display: none;
  margin-right: 5px;
}
div.repository-navigator div.repository-navigator-commandbar button[command] {
  vertical-align: middle;
}
div.repository-navigator div.repository-navigator-commandbar button[command] .icon {
  background: url("../images/repository_buttons.png");
  background-repeat: no-repeat;
  display: inline-block;
  height: 24px;
  margin-left: 3px;
  margin-right: 3px;
  width: 24px;
  vertical-align: middle;
}
div.repository-navigator div.repository-navigator-commandbar button[command]:focus {
  outline: none;
}
div.repository-navigator div.repository-navigator-commandbar button[command="create"] .icon {
  background-position: 0 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="upload"] .icon {
  background-position: -96px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="download"] .icon {
  background-position: -48px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="versions"] .icon {
  background-position: -120px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="delete"] .icon {
  background-position: -24px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="rename"] .icon {
  background-position: -72px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="moveTo"] .icon {
  background-position: -144px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="browseFolder"] .icon {
  background-position: -192px 0;
}
div.repository-navigator div.repository-navigator-commandbar button[command="copyTo"] .icon,
div.repository-navigator div.repository-navigator-commandbar button[command="restore"] .icon,
div.repository-navigator div.repository-navigator-commandbar button[command="promote"] .icon {
  background: none;
}
div.repository-navigator div.repository-navigator-commandbar button[command="copyTo"] .icon i,
div.repository-navigator div.repository-navigator-commandbar button[command="restore"] .icon i,
div.repository-navigator div.repository-navigator-commandbar button[command="promote"] .icon i {
  margin-top: 5px;
}
div.repository-navigator div.repository-navigator-commandbar button.enabled {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #fff;
}
div.repository-navigator div.repository-navigator-commandbar button.enabled:hover:not(.loading) {
  background-color: #80cfdb;
}
div.repository-navigator div.repository-navigator-commandbar button.loading {
  padding-left: 5px;
}
div.repository-navigator div.repository-navigator-commandbar button.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 3px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_white.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator div.repository-navigator-commandbar .dropdown-content {
  left: 0px;
  top: 100%;
}
div.repository-navigator div.repository-navigator-commandbar button.hover .dropdown-content,
div.repository-navigator div.repository-navigator-commandbar button:focus .dropdown-content {
  display: block;
}
div.repository-navigator .search {
  margin-bottom: 0px;
  padding: 5px;
  float: right;
  position: relative;
}
div.repository-navigator .search .fa-search {
  position: absolute;
  top: 14px;
  left: 10px;
}
div.repository-navigator .search .search-input {
  border-radius: 0;
  width: 100%;
  padding: 0 20px;
  height: 32px;
  box-shadow: none;
}
div.repository-navigator .search .search-input:focus {
  border-color: #80cfdb;
}
div.repository-navigator .search .search-clear {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #b3b3b3;
  cursor: pointer;
}
div.repository-navigator .search .search-clear:hover {
  color: #8f8f8f;
  text-decoration: none;
}
div.repository-navigator .repository-navigator-center {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 40px;
  bottom: 0px;
  overflow: hidden;
}
div.repository-navigator .repository-navigator-center .navigator-repositories {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 70%;
  bottom: 0px;
  border-right: solid 1px #c0c0c0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul {
  list-style: outside none none;
  padding: 5px 0;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 42px;
  bottom: 0;
  overflow: auto;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item {
  font-size: 14px;
  height: 28px;
  line-height: 28px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item .item-multiple-select {
  width: 24px;
  margin-left: -2px;
  height: 28px;
  top: 1px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item .item-multiple-select i.multiple-select {
  height: 18px;
  width: 18px;
  margin-top: 5px;
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-size: 72px 18px;
  display: inline-block;
  background-position: -18px 0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item.active {
  background-color: #b3e2e9;
  color: #222;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item.active i.multiple-select {
  background-position: -54px 0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item:not(.active):hover {
  background-color: #80cfdb;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item:not(.active):hover i.multiple-select {
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-position: -18px 0;
  background-size: 72px 18px;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 5px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item:not(.active):hover i.multiple-select:hover {
  background-position: -36px 0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item.loading:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 6px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item.loading.active:after {
  background: url("../images/spinner_white.png");
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item {
  cursor: pointer;
  padding-left: 23px;
  position: relative;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item span.item-name {
  margin-left: 23px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item .item-multiple-select {
  position: absolute;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item .item-multiple-select i {
  height: 20px;
  width: 20px;
  margin-top: 6px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"] {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  position: absolute;
  margin: 6px 0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"].img-local {
  background-position: -18px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"].img-publicapi {
  background-position: -108px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"].img-group {
  background-position: -162px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"].img-personal {
  background-position: -144px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"].img-sharepoint {
  background-position: 0px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.item i[class^="img-"].img-cmis {
  background-position: -90px;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.add-repository {
  font-size: 26px;
  text-align: center;
  color: #ccc;
  cursor: pointer;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.add-repository i {
  background-image: url('../images/repository_add.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background-position: 0 0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories ul li.add-repository:hover i {
  width: 25px;
  background-position: -24px 0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories:focus {
  outline: none;
}
div.repository-navigator .repository-navigator-center .navigator-repositories .title {
  cursor: default;
  height: 42px;
  padding-left: 20px;
  line-height: 42px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 20px;
  font-size: 150%;
  border-bottom: solid 1px #c0c0c0;
}
div.repository-navigator .repository-navigator-center .navigator-repositories .repositories-browser {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  overflow-x: auto;
  overflow-y: auto;
}
div.repository-navigator .repository-navigator-center .navigator-repositories .repositories-browser span.edit {
  margin-left: 23px;
  display: inline-block;
  width: 85%;
}
div.repository-navigator .repository-navigator-center .navigator-repositories .repositories-browser span.edit input {
  width: 99%;
  padding: 1px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #009eb6;
  line-height: 24px;
  height: 28px;
  font-size: 1em;
}
div.repository-navigator .repository-navigator-center .navigator-repositories .repositories-browser span.edit input:focus {
  border: 1px solid #009eb6;
  outline: none;
}
div.repository-navigator .repository-navigator-center .navigator-repositories .repositories-browser .item {
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree {
  position: absolute;
  top: 0px;
  left: 30%;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  background-color: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree:focus {
  outline: none;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status {
  border-bottom: solid 1px #c0c0c0;
  height: 42px;
  padding-left: 20px;
  line-height: 42px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.folderName {
  display: inline;
  padding-right: 20px;
  font-size: 150%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar {
  display: inline;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar span.path-separator {
  background: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: -70px;
  background-size: 120px 10px;
  display: inline-block;
  height: 10px;
  margin-left: 3px;
  margin-right: 3px;
  width: 10px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar span.path {
  color: #000000;
  cursor: pointer;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar span.path:hover {
  color: rgba(0, 0, 0, 0.8);
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar span.path.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 14px;
  display: inline-block;
  vertical-align: top;
  margin: 0 3px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar span:not(.path):not(.path-separator) {
  color: #666;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .repository-navigator-status div.locationBar.loading:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 15px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column {
  position: absolute;
  top: 42px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header {
  height: 30px;
  line-height: 30px;
  position: relative;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header .multiple-select {
  margin-left: -2px;
  margin-top: 5px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header .multiple-select i {
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-size: 72px 18px;
  background-position: 0px 0;
  display: inline-block;
  height: 18px;
  width: 18px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header .multiple-select i:hover:not(.clicked) {
  border-radius: 25px;
  background-color: #b3e2e9;
  background-position: -36px 0;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header .multiple-select i.clicked {
  border-radius: 25px;
  background-color: #b3e2e9;
  background-position: -54px 0;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header span.sortable-header {
  width: 100%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header span.sortable-header i {
  color: #999;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10%;
  top: 30%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .header span.sortable-header i.fa-sort {
  opacity: 0.4;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .details {
  position: relative;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .details > span {
  position: absolute;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .details > span.item-name {
  left: 0%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .details > span.lastUpdated {
  left: 33%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column .details > span.updatedBy {
  left: 66%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul {
  list-style: outside none none;
  padding: 0 0 5px 0;
  width: 100%;
  margin: 0;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li {
  padding-left: 15px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item {
  font-size: 14px;
  height: 28px;
  line-height: 28px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .item-multiple-select {
  width: 24px;
  margin-left: -2px;
  height: 28px;
  top: 1px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .item-multiple-select i.multiple-select {
  height: 18px;
  width: 18px;
  margin-top: 5px;
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-size: 72px 18px;
  display: inline-block;
  background-position: -18px 0;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.active {
  background-color: #b3e2e9;
  color: #222;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.active i.multiple-select {
  background-position: -54px 0;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item:not(.active):hover {
  background-color: #80cfdb;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item:not(.active):hover i.multiple-select {
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-position: -18px 0;
  background-size: 72px 18px;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 5px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item:not(.active):hover i.multiple-select:hover {
  background-position: -36px 0;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.loading:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 6px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.loading.active:after {
  background: url("../images/spinner_white.png");
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) {
  height: 60px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
  padding: 0 15px;
  height: 28px;
  line-height: 26px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.enabled {
  cursor: pointer;
  color: #333;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button + button {
  margin-left: 5px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.loading {
  padding-left: 10px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.enabled {
  background-color: #009eb6;
  color: #fff;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.loading:before {
  background: url("../images/spinner_white.png");
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.openSave:not(.saveAs) button.loading:before {
  top: 4px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs {
  height: 34px;
  padding: 3px 0 3px 35px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.item-name.edit {
  width: 33%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn {
  left: 33%;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
  padding: 0 15px;
  height: 28px;
  line-height: 26px;
  display: inline-block;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.enabled {
  cursor: pointer;
  color: #333;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button + button {
  margin-left: 5px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.loading {
  padding-left: 10px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.enabled {
  background-color: #009eb6;
  color: #fff;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.loading:before {
  background: url("../images/spinner_white.png");
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.saveAs span.saveAsBtn button.loading:before {
  margin-top: 2px;
  margin-right: 4px;
  margin-left: 0;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.empty {
  text-align: center;
  margin-top: 1em;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item {
  cursor: default;
  position: relative;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item i[class^="img-"] {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  position: absolute;
  height: 18px;
  margin-top: 5px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item i[class^="img-"].img-file {
  background-position: -72px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item i[class^="img-"].img-folder {
  background-position: -54px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .details span.item-name > .name {
  margin-left: 30px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .details span.item-name {
  cursor: pointer;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .details span.edit:not(.item-name) {
  margin-left: 30px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .details span.edit {
  display: inline-block;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .details span.edit input {
  width: 99%;
  padding: 1px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #009eb6;
  line-height: 24px;
  height: 28px;
  font-size: 1em;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item .details span.edit input:focus {
  border: 1px solid #009eb6;
  outline: none;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.loading i {
  display: none;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.loading:after {
  left: 27px;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .navigator-column ul li.item.loading.saveAs:after {
  background: none;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .search-more {
  text-align: center;
  color: #333;
  margin-top: 15px;
  color: #009eb6;
}
div.repository-navigator .repository-navigator-center .repository-navigator-tree .search-more:hover {
  color: #80cfdb;
  cursor: pointer;
}
div.repository-navigator .repository-navigator-center .selectMessage {
  font-size: 1.5em;
  left: 30%;
  top: 45%;
  right: 0;
  position: absolute;
  z-index: -1;
  text-align: center;
}
div.repository-navigator .dragInProgress {
  border: 5px dashed #A0A0A0;
}
div.repository-navigator .dragInProgress * {
  pointer-events: none;
}
div.repository-navigator.readOnly .repository-navigator-center {
  top: 0;
}
div.repository-navigator .search-clear {
  display: none;
}
div.repository-navigator.search [command="create"],
div.repository-navigator.search [command="copyTo"],
div.repository-navigator.search [command="moveTo"],
div.repository-navigator.search [command="restore"] {
  display: none !important;
}
div.repository-navigator.search .search-clear {
  display: inline;
}
div.repository-navigator.globalSearch .navigator-column .header i.fa {
  display: none;
}
div.repository-navigator.globalSearch .navigator-column .global-search-repo-name {
  color: #009eb6;
  margin-top: 5px;
  margin-bottom: 5px;
}
.repository-navigator-contextual-menu {
  position: fixed;
  background: #ffffff;
  top: 10px;
  left: 10px;
  padding: 5px 0;
  color: #808080;
  cursor: default;
  border-style: solid;
  border-width: 1px;
  border-color: #7d7d7d;
  z-index: 100;
}
.repository-navigator-contextual-menu.add-repository {
  position: absolute;
}
.repository-navigator-contextual-menu:focus {
  outline: none;
}
.repository-navigator-contextual-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
}
.repository-navigator-contextual-menu li {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
}
.repository-navigator-contextual-menu li.enabled {
  color: #222222;
  cursor: pointer;
}
.repository-navigator-contextual-menu li.enabled:hover {
  background-color: #80cfdb;
}
.repository-navigator-contextual-menu li.loading:after {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  left: 10px;
  top: 5px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.repository-navigator-contextual-menu li .dropdown-content {
  left: 100%;
  top: 0px;
}
.repository-navigator-contextual-menu li .dropdown-content.onLeft {
  left: 0;
  transform: translateX(-100%);
}
.repository-navigator-contextual-menu li:hover .dropdown-content,
.repository-navigator-contextual-menu li:focus .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  text-align: left;
  white-space: nowrap;
}
.dropdown-content span.item-name > .name {
  margin-left: 5px;
}
.dropdown-content i {
  vertical-align: middle;
}
.dropdown-content i.img-folder {
  display: inline-block;
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-position: -54px;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* To override when used inside the products*/
.ribbon-bar .side-panel,
.ribbon-bar .side-panel *,
.ribbon-bar .side-panel *:before,
.ribbon-bar .side-panel *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.side-panel {
  color: #666666;
}
.side-panel,
.side-panel *,
.side-panel *:before,
.side-panel *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.side-panel .backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
}
.side-panel .place-container i[class^="img-"] {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: absolute;
}
.side-panel .place-container i[class^="img-"].img-local {
  background-position: -18px;
}
.side-panel .place-container i[class^="img-"].img-publicapi {
  background-position: -108px;
}
.side-panel .place-container i[class^="img-"].img-group {
  background-position: -162px;
}
.side-panel .place-container i[class^="img-"].img-personal {
  background-position: -144px;
}
.side-panel .place-container i[class^="img-"].img-sharepoint {
  background-position: 0px;
}
.side-panel .place-container i[class^="img-"].img-cmis {
  background-position: -90px;
}
.side-panel .place-container span {
  margin-left: 23px;
}
.side-panel .panel-content {
  width: 350px;
  background-color: #FFFFFF;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  box-shadow: -30px 0 30px -30px rgba(0, 0, 0, 0.4);
}
.side-panel .panel-content .item {
  height: 28px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
.side-panel .panel-content .item i.img-folder {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  background-position: -54px;
}
.side-panel .panel-content .item span {
  margin-left: 5px;
  vertical-align: middle;
}
.side-panel .panel-content .panel-header {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
}
.side-panel .panel-content .panel-header .title {
  margin-left: 10px;
  margin-bottom: 10px;
}
.side-panel .panel-content .panel-header .label-from,
.side-panel .panel-content .panel-header .label-to {
  font-size: 12px;
  font-weight: bold;
}
.side-panel .panel-content .panel-header .origin-place {
  margin-left: 10px;
  margin-top: 10px;
  font-size: 12px;
}
.side-panel .panel-content .panel-header .origin-place span {
  font-weight: 700;
}
.side-panel .panel-content .panel-header .origin-path {
  margin: 10px 0 3px 10px;
  font-weight: bold;
  font-size: 12px;
}
.side-panel .panel-content .panel-header .selected-element {
  font-size: 12px;
  margin-left: 10px;
}
.side-panel .panel-content .panel-header .selected-element .item {
  line-height: 28px;
}
.side-panel .panel-content .panel-header .selected-element i {
  vertical-align: middle;
}
.side-panel .panel-content .panel-header .selected-element i.img-folder {
  display: inline-block;
}
.side-panel .panel-content .panel-header .destination-place {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 10px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section {
  width: 100%;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .changeplace {
  width: 100%;
  margin-top: 10px;
  background-color: white;
  border: solid 1px #ccc;
  padding: 4px 7px 4px 10px;
  box-shadow: none;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .changeplace .current-place {
  float: left;
  margin-left: 23px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
  max-width: 85%;
  overflow-x: hidden;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .changeplace .current-place.select-place {
  color: #999;
  margin-left: 0px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .changeplace .caret {
  float: right;
  margin-top: 7px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .changeplace .repository-icon {
  float: left;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu {
  width: 100%;
  max-width: 320px;
  font-size: 12px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a {
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 10px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"] {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: absolute;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"].img-local {
  background-position: -18px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"].img-publicapi {
  background-position: -108px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"].img-group {
  background-position: -162px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"].img-personal {
  background-position: -144px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"].img-sharepoint {
  background-position: 0px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a i[class^="img-"].img-cmis {
  background-position: -90px;
}
.side-panel .panel-content .panel-header .destination-place .change-place-section .dropdown-menu li a span {
  margin-left: 23px;
  vertical-align: middle;
}
.side-panel .panel-content .panel-header hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.side-panel .panel-content .panel-header .places-selection {
  margin: 4px 0 8px 10px;
}
.side-panel .panel-content .panel-header .places-selection #select-place {
  font-size: 12px;
}
.side-panel .panel-content .panel-header .locationBar {
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 300px;
}
.side-panel .panel-content .panel-header .locationBar .path-element {
  background: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-size: 120px 10px;
  display: inline-block;
  height: 10px;
  margin-left: 3px;
  margin-right: 3px;
  width: 10px;
}
.side-panel .panel-content .panel-header .locationBar .path-back {
  cursor: pointer;
  background-position: -110px;
}
.side-panel .panel-content .panel-header .locationBar span.path-separator {
  background-position: -70px;
  margin-bottom: 3px;
}
.side-panel .panel-content .panel-header .locationBar .path {
  cursor: pointer;
}
.side-panel .panel-content .panel-header .locationBar :last-child {
  font-weight: bold;
}
.side-panel .panel-content .panel-header .locationBar span.short-path {
  max-width: 80px;
  height: 14px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
  overflow-x: hidden;
}
.side-panel .panel-content .panel-header .origin-path .path-element {
  background: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-size: 120px 10px;
  display: inline-block;
  height: 10px;
  margin-left: 3px;
  margin-right: 3px;
  width: 10px;
}
.side-panel .panel-content .panel-header .origin-path span.path-separator {
  background-position: -70px;
  margin-bottom: 3px;
}
.side-panel .panel-content .panel-body {
  border-bottom: 1px solid #eaeaea;
}
.side-panel .panel-content .panel-body .destination-header {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 10px;
}
.side-panel .panel-content .panel-body .item {
  cursor: pointer;
}
.side-panel .panel-content .panel-body .item i {
  display: inline-block;
}
.side-panel .panel-content .panel-body .repository-content {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.side-panel .panel-content .panel-body .repository-content .folder:hover {
  background-color: #80cfdb;
}
.side-panel .panel-content .panel-body .repository-content div.content {
  height: 28px;
  line-height: 28px;
  padding-left: 5px;
}
.side-panel .panel-content .panel-body .repository-content div.empty {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
}
.side-panel .panel-content .panel-body .repository-content #add-new-folder,
.side-panel .panel-content .panel-body .repository-content #add-new-foldername {
  cursor: pointer;
}
.side-panel .panel-content .panel-body .repository-content #add-new-folder span,
.side-panel .panel-content .panel-body .repository-content #add-new-foldername span,
.side-panel .panel-content .panel-body .repository-content #add-new-folder input,
.side-panel .panel-content .panel-body .repository-content #add-new-foldername input {
  margin-left: 5px;
}
.side-panel .panel-content .panel-body .repository-content #add-new-folder span,
.side-panel .panel-content .panel-body .repository-content #add-new-foldername span {
  vertical-align: middle;
}
.side-panel .panel-content .panel-body .repository-content #add-new-folder input,
.side-panel .panel-content .panel-body .repository-content #add-new-foldername input {
  width: 200px;
}
.side-panel .panel-content .panel-body .repository-content #add-new-foldername {
  display: none;
}
.side-panel .panel-content .panel-body .repository-content #add-new-foldername input {
  height: 28px;
  line-height: 24px;
  padding: 5px;
  border: 1px solid #009eb6;
  font-size: 1em;
}
.side-panel .panel-content .panel-body .repository-content #add-new-foldername input:focus {
  outline: none;
  outline-style: none;
}
.side-panel .panel-content .panel-body .repository-content #add-new-folder .new-folder {
  vertical-align: middle;
}
.side-panel .panel-content .panel-body .repository-content .new-folder {
  background: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  display: inline-block;
  height: 18px;
  width: 18px;
  background-position: -180px;
  vertical-align: middle;
}
.side-panel .panel-content .footer {
  padding: 15px;
}
.side-panel .panel-content .footer button {
  border: 1px solid #ababab;
  padding: 2px 10px;
  background-color: #ffffff;
  color: grey;
  cursor: default;
  outline: none;
  border-color: #009eb6;
  border-radius: 0;
  margin-left: 10px;
  margin-top: 15px;
  color: white;
  background-color: #009eb6;
}
.side-panel .panel-content .footer button.enabled {
  cursor: pointer;
  color: #333;
}
.side-panel .panel-content .footer button.enabled:hover:not(.loading) {
  background-color: #80cfdb;
  border-color: #009eb6;
}
.side-panel .panel-content .footer button + button {
  margin-left: 5px;
}
.side-panel .panel-content .footer button.loading {
  padding-left: 10px;
}
.side-panel .panel-content .footer button.loading:before {
  content: '';
  height: 18px;
  width: 18px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  background: url("../images/spinner_black.png");
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.side-panel .panel-content .footer button.enabled {
  background-color: #009eb6;
  color: #fff;
}
.side-panel .panel-content .footer button.loading:before {
  background: url("../images/spinner_white.png");
}
.side-panel .panel-content .footer button:hover {
  background-color: #80cfdb;
}
.side-panel .panel-content .footer button.disabled {
  color: #333;
  background-color: #fff;
  border-color: #ababab;
}
.side-panel .panel-content .title {
  font-size: 16px;
  font-weight: bold;
}
.side-panel .panel-content button.close {
  position: absolute;
  outline: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  right: 15px;
  top: 15px;
  background: transparent;
  -webkit-appearance: none;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.tox.tox-tinymce {
  z-index: 1060;
}
.side-panel.side-panel-issue-editor .panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.side-panel.side-panel-issue-editor .panel-content button.showDescription {
  position: absolute;
  outline: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  right: 15px;
  top: 15px;
  background: transparent;
  -webkit-appearance: none;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  right: 28px;
  top: 19px;
  font-size: 15px;
}
.side-panel.side-panel-issue-editor .panel-content button.showDescription.toggled {
  color: #009eb6;
  opacity: 1;
}
.side-panel.side-panel-issue-editor .panel-content button.exportCSV {
  position: absolute;
  outline: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  right: 15px;
  top: 15px;
  background: transparent;
  -webkit-appearance: none;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  right: 50px;
  top: 19px;
  font-size: 15px;
}
.side-panel.side-panel-issue-editor .panel-content .panel-header {
  flex-grow: 0;
  flex-shrink: 0;
  height: auto;
}
.side-panel.side-panel-issue-editor .panel-content .panel-body {
  padding: 2px;
  flex: 1;
  height: 80%;
}
.side-panel.side-panel-issue-editor .panel-content .panel-body #issueEditorPlugin {
  height: 100%;
}
.side-panel.side-panel-restore-files .panel-content {
  width: 800px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header {
  height: 130px;
  min-height: 125px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item {
  margin-left: 10px;
  margin-top: 30px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"] {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: absolute;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"].img-local {
  background-position: -18px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"].img-publicapi {
  background-position: -108px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"].img-group {
  background-position: -162px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"].img-personal {
  background-position: -144px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"].img-sharepoint {
  background-position: 0px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item i[class^="img-"].img-cmis {
  background-position: -90px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .place-restore-item span {
  margin-left: 23px;
}
.side-panel.side-panel-restore-files .panel-content .panel-header .content-header {
  margin: 10px 12px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body {
  max-height: 80%;
  overflow-y: auto;
}
.side-panel.side-panel-restore-files .panel-content .panel-body .content-header {
  margin-left: 10px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul {
  list-style: outside none none;
  overflow-x: auto;
  overflow-y: auto;
  padding-left: 15px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header {
  height: 30px;
  line-height: 30px;
  position: relative;
  padding-left: 10px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .details {
  position: relative;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .details > span {
  position: absolute;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .details > span.name {
  left: 4%;
  cursor: default;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .details > span.path {
  left: 40%;
  cursor: default;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .details > span.deleted-date {
  left: 75%;
  cursor: default;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .multiple-select {
  margin-left: -2px;
  margin-top: 5px;
  cursor: pointer;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .multiple-select i {
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-size: 72px 18px;
  background-position: 0px 0;
  display: inline-block;
  height: 18px;
  width: 18px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .multiple-select i:hover:not(.clicked) {
  border-radius: 25px;
  background-color: #b3e2e9;
  background-position: -36px 0;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .header .multiple-select i.clicked {
  border-radius: 25px;
  background-color: #b3e2e9;
  background-position: -54px 0;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .empty {
  margin-top: 20px;
  text-align: center;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item {
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  padding-left: 10px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .item-multiple-select {
  width: 24px;
  margin-left: -2px;
  height: 28px;
  top: 1px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .item-multiple-select i.multiple-select {
  height: 18px;
  width: 18px;
  margin-top: 5px;
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-size: 72px 18px;
  display: inline-block;
  background-position: -18px 0;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item.active {
  background-color: #b3e2e9;
  color: #222;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item.active i.multiple-select {
  background-position: -54px 0;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item:not(.active):hover {
  background-color: #80cfdb;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item:not(.active):hover i.multiple-select {
  background: url('../images/repository_icons_multiselect.png');
  background-repeat: no-repeat;
  background-position: -18px 0;
  background-size: 72px 18px;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 5px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item:not(.active):hover i.multiple-select:hover {
  background-position: -36px 0;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .details {
  position: relative;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .details > span {
  position: absolute;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-left: 0px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .details > span.item-name {
  left: 4%;
  max-width: 32%;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .details > span.item-name .name {
  vertical-align: top;
  margin-left: 10px;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .details > span.item-path {
  left: 40%;
  max-width: 30%;
}
.side-panel.side-panel-restore-files .panel-content .panel-body ul .item .details > span.item-deleted-date {
  left: 75%;
  max-width: 15%;
}
.side-panel.side-panel-restore-files .panel-content .footer button {
  margin: auto;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"] {
  background-image: url('../images/repository_icons_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: absolute;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"].img-local {
  background-position: -18px;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"].img-publicapi {
  background-position: -108px;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"].img-group {
  background-position: -162px;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"].img-personal {
  background-position: -144px;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"].img-sharepoint {
  background-position: 0px;
}
.side-panel.side-panel-promote-place .panel-content i[class^="img-"].img-cmis {
  background-position: -90px;
}
.side-panel.side-panel-promote-place .panel-content .panel-header span {
  margin-left: 23px;
}
.side-panel.side-panel-promote-place .panel-content .panel-header .content-header {
  margin: 10px 12px;
}
.side-panel.side-panel-promote-place .panel-content .panel-header .description {
  font-size: 12px;
  margin: 10px 10px 15px 5px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .label {
  font-size: 12px;
  font-weight: bold;
  color: inherit;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 10px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section {
  width: 100%;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .changeplace {
  width: 100%;
  margin-top: 10px;
  background-color: white;
  border: solid 1px #ccc;
  padding: 4px 7px 4px 10px;
  box-shadow: none;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .changeplace .current-place {
  float: left;
  margin-left: 23px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
  max-width: 85%;
  overflow-x: hidden;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .changeplace .current-place.select-place {
  color: #999;
  margin-left: 0px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .changeplace .caret {
  float: right;
  margin-top: 7px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .changeplace .repository-icon {
  float: left;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .dropdown-menu {
  width: 100%;
  max-width: 320px;
  font-size: 12px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .dropdown-menu li a {
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 10px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .target-place .change-place-section .dropdown-menu li a span {
  margin-left: 23px;
  vertical-align: middle;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 10px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section {
  width: 100%;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .state-btn {
  width: 100%;
  margin-top: 10px;
  background-color: white;
  border: solid 1px #ccc;
  padding: 4px 7px 4px 10px;
  box-shadow: none;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .state-btn .current-state {
  float: left;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
  max-width: 85%;
  overflow-x: hidden;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .state-btn .current-state.select-state {
  color: #999;
  margin-left: 0px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .state-btn .caret {
  float: right;
  margin-top: 7px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .dropdown-menu {
  width: 100%;
  max-width: 320px;
  font-size: 12px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .dropdown-menu li a {
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 10px;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .state .state-section .dropdown-menu li a span {
  margin-left: 23px;
  vertical-align: middle;
}
.side-panel.side-panel-promote-place .panel-content .panel-body .version {
  display: flex;
}
.side-panel.side-panel-promote-place .panel-content .panel-body input {
  margin: 10px 0 10px 10px;
}
.side-panel.side-panel-promote-place .panel-content .footer button {
  margin: auto;
}
